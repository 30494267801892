<template>
  <div class="deviceSkim">
    <div
      class="facilityBox"
      id="trajectory"
      ref="trajectory"
      style="min-width: 1376px;width:100%;box-sizing: border-box;"
    >
      <!-- 装货地卸货地 -->
      <div class="content_box">
        <div v-loading="loading" class="mapBox">
          <div style="position: relative">
            <div class="searchBox" style="height: 7vh">
              <el-form
                class="deviceForm"
                :model="bindForm"
                ref="bindForm"
                :inline="true"
              >
                <el-form-item
                  label="车牌号或设备IMEI"
                  prop="VehicleCarNumber"
                  label-width="126px"
                >
                  <el-input
                    v-model="bindForm.VehicleCarNumber"
                    placeholder="车牌号或设备IMEI"
                    style="width: 146px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="车牌颜色">
                  <el-select
                    v-model="vehicleColor"
                    placeholder="请选择车辆颜色"
                    style="width: 76px"
                  >
                    <el-option label="蓝色" value="1"></el-option>
                    <el-option label="黄色" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="选择时间范围"
                  prop="time"
                  label-width="100px"
                >
                  <!--  @change="handleTime" -->
                  <el-date-picker
                    :default-time="defaultTime"
                    style="width: 356px"
                    v-model="bindForm.time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="medium"
                    @click="search('bindForm')"
                    >搜索</el-button
                  >
                  <el-button
                    type="primary"
                    size="medium"
                    @click="resetForm('bindForm')"
                    >清空</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div>
              <div id="container" style="width:auto;height:76vh;"></div>
              <ul class="btn-wrap" style="z-index: 9999999;" v-show="btnShow">
                <li class="light btn" @click="startMove">开始</li>
                <li class="night btn" @click="pauseMove">暂停</li>
                <li class="night btn" @click="resumeMove">继续</li>
              </ul>
              <div class="searchAddress">
                <el-input
                  placeholder="请输入需要搜索的地点"
                  clearable
                  v-model="searchAddress"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="handleSearchAddress"
                  ></el-button>
                </el-input>
              </div>
              <div class="searchAddress" style="left: 645px">
                <el-button
                  class="item_btn"
                  type="primary"
                  @click.stop="handleMeasure"
                  icon="el-icon-s-promotion"
                  >测距</el-button
                >
              </div>
              <!-- 设备列表 -->
              <el-collapse v-model="activeNames" class="collapse_box">
                <el-collapse-item title="车牌号和设备IMEI" name="1">
                  <div style="padding: 2px 6px">
                    <el-input
                      placeholder="请输入车牌号或设备IMEI"
                      v-model="pagination.searchWord"
                      class="input-with-select"
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="searchWord"
                      ></el-button>
                    </el-input>
                  </div>
                  <div>
                    <ul
                      class="electric_box"
                      v-infinite-scroll="load"
                      infinite-scroll-disabled="disabled"
                      style="overflow:auto"
                    >
                      <li
                        v-for="(item, index) in tableDataList"
                        :class="isactive == index ? 'active' : ''"
                        class="infinite-list-item"
                        @click="searchitem(item, index)"
                        :key="index"
                      >
                        <span
                          ><span style="font-weight: 600">设备IMEI: </span>
                          {{ item.IMEI }}</span
                        >
                        <span style="margin-left: 20px"
                          ><span style="font-weight: 600">备注: </span>
                          {{ item.Note }}</span
                        >
                      </li>
                      <p class="load_item" v-if="loadingScroll">加载中...</p>
                      <p class="load_item" v-if="noMore">没有更多了</p>
                    </ul>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
        <div class="shipment">
          <div class="shipment_item">
            <div class="address_box" style="height: 35vh">
              <div
                class="address_item"
                style="cursor: pointer;color: #409EFF"
                v-for="(item, index) in fenceList"
                :key="index"
                @click="handleItem(item)"
              >
                <div style="font-weight: 600;min-width: 48px">
                  {{ `围栏${index + 1}` }}：
                </div>
                <div style="border-bottom: 1px dashed #409EFF;">
                  {{ item.address }}
                </div>
                <el-button
                  class="item_btn"
                  type="text"
                  size="mini"
                  @click.stop="removeFence(item, index)"
                  >删除</el-button
                >
              </div>
            </div>
            <el-button
              type="primary"
              class="addressFence_search"
              size="medium"
              @click="fenceSearch"
              >装货地卸货地查询</el-button
            >
          </div>
          <div
            class="shipment_item"
            style="border-top:none"
            v-loading="loadingFence"
          >
            <div class="address_box">
              <div
                class="address_item"
                v-for="(item, index) in addressList"
                :key="index"
              >
                <div class="item_address">
                  <p>
                    <span style="font-weight: 600">装货地：</span
                    ><span style="border-bottom: 1px dashed">{{
                      item.LoadAddress
                    }}</span>
                  </p>
                  <p>
                    <span style="font-weight: 600">卸货地：</span
                    ><span style="border-bottom: 1px dashed">{{
                      item.UnLoadAddress
                    }}</span>
                  </p>
                </div>
                <el-button
                  class="item_btn"
                  type="text"
                  size="mini"
                  @click="removeAddress(item)"
                  >删除</el-button
                >
              </div>
            </div>
            <el-button
              type="primary"
              class="addressFence_search"
              size="medium"
              @click="locusSearch"
              >轨迹查询</el-button
            >
          </div>
        </div>
      </div>
      <div id="locusTable">
        <div class="locus_btnBox">
          <el-button
            type="danger"
            class="address_search"
            style="float:left"
            size="medium"
            @click="removeAlllocus"
            >批量删除</el-button
          >
          <div class="mileage" style="display:inline" v-if="ismileage">
            <span
              ><strong>轨迹里程：</strong
              >{{ Number(mileageNum).toFixed(2) }}KM</span
            >
            <span style="margin-left: 10px" v-if="isBD"
              ><strong>北斗里程：</strong
              >{{ Number(mileageNumBD).toFixed(2) }}KM</span
            >
          </div>

          <el-input
            placeholder="请输入有效里程（KM）"
            v-model="mileage"
            class="input-with-select"
            style="width: 302px;margin-left: 20px"
          >
            <el-select v-model="selecttype" slot="prepend" style="width:74px">
              <el-option label="大于" :value="1"></el-option>
              <el-option label="小于" :value="0"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleMileage"
            ></el-button>
          </el-input>
          <el-button
            type="primary"
            class="address_search"
            size="medium"
            @click="exportExcel"
            >导出轨迹</el-button
          >
        </div>
        <!-- v-if="isDanger" -->
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          highlight-current-row
          v-loading="loadingTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" label="编号" />
          <el-table-column prop="VehicleNumber" label="车牌号/设备IMEI">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.VehicleNumber }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="Mileage" label="里程（km）">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.Mileage }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="loadingPoint" label="装货地址">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.loadingPoint }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="unloadingPoint" label="卸货地址">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.unloadingPoint }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="装货时间">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.startDate }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="endDate" label="卸货时间">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.dander || scope.row.IsUnusual ? 'warning-row' : ''
                "
                >{{ scope.row.endDate }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template
              slot-scope="scope"
              style="display: flex;justify-content: center;"
            >
              <el-button
                type="primary"
                size="small"
                @click="detailTrajectory(scope.row)"
                >查看轨迹</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="removelocus(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getEquipDetail,
  GetEquipOverviewLoadingPointList,
  getEquipTrack,
  GetMileageInfo,
  ExportMileageInfo,
  DelTrackInfo,
} from "@/api/platform/deviceSkim/index";
import start from "../../../assets/static/start.png";
import end from "../../../assets/static/end.png";
import address from "@/assets/static/address.png";
import { reverseCoord } from "@/api/common/common";
import { equipmentsList } from "@/api/platform/deviceManage/index";
import car from "../../../assets/static/car1.png";
export default {
  data() {
    return {
      innerVisible: false,
      loading: false,
      type: 0, // 判断是查询点 还是查询轨迹 1是点 2是轨迹
      site: {
        lng: "",
        lat: "",
      }, // 中心点
      bindForm: {
        VehicleCarNumber: "", // 车牌号
        deviceNum: "", // 设备名
        time: [], // 时间
        status: "", // 状态
        deviceOwner: "", // 设备所有人
        DriverPhone: "", // 司机电话号码
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "禁用",
        },
      ],
      stateList: [
        {
          id: 1,
          name: "是否启用",
          isActive: 1,
        },
        {
          id: 2,
          name: "是否激活",
          isActive: 1,
        },
        {
          id: 3,
          name: "是否过期",
          isActive: 1,
        },
        {
          id: 4,
          name: "ACG状态",
          isActive: 1,
        },
        {
          id: 5,
          name: "网络状态",
          isActive: 1,
        },
      ], // 设备状态列表
      //  设备详情
      deviceInfo: {},
      traceInfo: [], // 轨迹信息
      map: null, // 地图
      marker: null,
      btnShow: false, // 是否展示按钮组
      defaultTime: ["00:00:00", "23:59:59"], // 默认的选择时间
      points: [],
      activeNames: ["1"],
      addressList: [], // 装货地卸货地
      fenceList: [], // 围栏
      vehicleColor: "2",
      newsite: {
        lng: "",
        lat: "",
      },
      newtraceInfo: [], // 轨迹信息
      polylineLayer: null,
      tableData: [],
      redisKey: "",
      infoWindow: null,
      circle: null, // 原型覆盖物
      radiusValue: 1000,
      PointLIst: [], //
      PointFlag: 0,
      loadingFence: false,
      loadingTable: false,
      newArrList: [],
      mileage: "",
      // isDanger: false,
      mileageList: [],
      container: null,
      tableDataList: [],
      isactive: null,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        total: 10, //总条数
        searchWord: "",
      },
      loadingScroll: false,
      noMore: false,
      isBD: false,
      ismileage: false,
      mileageNum: 0,
      mileageNumBD: 0,
      selectList: [],
      selecttype: 1,
      searchAddress: "", // 切换当前位置
      markers: null,
      geocoder: null,
      measureTool: null, // 测距
    };
  },
  mounted() {
    this.initMap();
    this.geocoder = new TMap.service.Geocoder();
    this.markers = new TMap.MultiMarker({
      map: this.map,
      geometries: [],
    });
  },
  computed: {
    disabled() {
      return this.loadingScroll || this.noMore;
    },
  },
  created() {
    this.getNowTime();
    window.shipment = this.shipment;
    window.unloading = this.unloading;
    window.copyText = this.copyText;
    window.deleteText = this.deleteText;
  },
  methods: {
    onchanges(val) {
      console.log(val);
    },
    // 加载车牌设备
    load() {
      this.loadingScroll = true;
      let params = {
        pageIndex: this.pagination.page++,
        pageSize: 10,
        searchWord: this.pagination.searchWord,
      };
      equipmentsList(JSON.stringify(params))
        .then((res) => {
          this.tableDataList = this.tableDataList.concat(res.data.DataList);
          this.pagination.total = Number(res.data.TotalCount);
          if (
            Math.ceil(Number(res.data.TotalCount) / 10) < this.pagination.page
          ) {
            this.noMore = true;
          }
          this.loadingScroll = false;
        })
        .catch((err) => {
          if (err.code === 403) {
            this.$router.push("/power/nopermission");
          }
        });
    },
    // 点击设备搜索
    searchitem(row, index) {
      this.bindForm.VehicleCarNumber = row.IMEI;
      this.isactive = index;
      this.getEquipDetail();
    },
    searchWord() {
      this.pagination.page = 1;
      this.tableDataList = [];
      this.load();
    },
    search(bindForm) {
      if (!this.bindForm.VehicleCarNumber) {
        this.$message.warning("请输入车牌号或设备IMEI");
        return;
      }
      if (this.bindForm.VehicleCarNumber && this.bindForm.time.length > 0) {
        this.getEquipTrack();
      } else if (
        this.bindForm.VehicleCarNumber &&
        this.bindForm.time.length == 0
      ) {
        this.getEquipDetail();
      }
      this.tableData = [];
      this.addressList = [];
      this.fenceList = [];
    },
    resetForm(bindForm) {
      this.$refs[bindForm].resetFields();
      this.tableData = [];
      this.addressList = [];
      this.fenceList = [];
      this.map.destroy();
      this.initMap();
      this.ismileage = false;
      this.mileageNum = 0;
      this.mileageNumBD = 0;
      this.isBD = 0;
    },
    // 获取设备详情
    getEquipDetail() {
      let params = {
        //imei: this.bindForm.deviceNum
        searchWord: this.bindForm.VehicleCarNumber,
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
      };
      this.loading = true;
      getEquipDetail(params)
        .then((res) => {
          this.deviceInfo = res.data;
          this.stateList[0].isActive = res.data.IsUse;
          this.stateList[1].isActive = res.data.IsActive;
          this.stateList[2].isActive = res.data.IsValid;
          this.stateList[3].isActive = res.data.ACGStatus;
          this.stateList[4].isActive = res.data.NetworkStatus;
          this.site.lng = res.data.Longitude;
          this.site.lat = res.data.Latitude;
          this.type = 1;
          this.btnShow = false;
          this.loading = false;
          this.ismileage = false;
          this.mileageNum = 0;
          this.mileageNumBD = 0;
          this.isBD = 0;
          this.setSite();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 处理选择时间
    handleTime(val) {
      var date = new Date();
      let data1 = new Date(val[0]).getTime();
      let data2 = new Date(val[1]).getTime();
      if (data2 - data1 > 86400000 * 10) {
        this.$message.warning("只能查询10天内的轨迹");
        this.bindForm.time = [];
        return;
      }
      this.getNowTime();
    },
    // 获取当前的时分秒
    getNowTime() {
      let time = new Date();
      let hh = time.getHours();
      let mf =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      let ss =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
      this.defaultTime[1] = hh + ":" + mf + ":" + ss;
    },
    // 查询轨迹
    getEquipTrack() {
      let params = {
        searchWord: this.bindForm.VehicleCarNumber,
        stDate: this.bindForm.time[0],
        edDate: this.bindForm.time[1],
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
        redisKey: this.redisKey,
      };
      this.loading = true;
      this.points = [];
      getEquipTrack(params)
        .then((res) => {
          this.isBD = res.data.isBD;
          this.mileageNum = res.data.mileageNum;
          this.mileageNumBD = res.data.mileageNumBD;
          this.trackKey = res.data.redisKey;
          this.ismileage = true;
          if (res.data.list.length > 0) {
            this.redisKey = res.data.redisKey;
            let list = res.data.list;
            this.site.lng = list[0].Lng;
            this.site.lat = list[0].Lat;
            let arr = list.map((item) => {
              return new TMap.LatLng(item.Lat, item.Lng);
            });
            this.traceInfo = arr;
            this.type = 2;
            this.btnShow = true;
            this.loading = false;
            let newArr = list.slice();
            newArr.forEach((item, index) => {
              if (item.IsStop) {
                this.points.push({
                  id: index + 2,
                  styleId:
                    index === 0
                      ? "start"
                      : index === newArr.length - 1
                      ? "end"
                      : "newmarker",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  Address: item.Address,
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    IsStop: item.IsStop,
                    TimeDiffer: item.TimeDiffer,
                    id: item.ID,
                  },
                });
              } else {
                this.points.push({
                  id: index + 2,
                  styleId:
                    index === 0
                      ? "start"
                      : index === newArr.length - 1
                      ? "end"
                      : "marker",
                  position: new TMap.LatLng(item.Lat, item.Lng),
                  Address: item.Address,
                  properties: {
                    //自定义属性
                    time: item.GPSTime,
                    IsStop: item.IsStop,
                    TimeDiffer: item.TimeDiffer,
                    id: item.ID,
                  },
                });
              }
            });
            // 设置中心点
            this.setRoute();
          } else {
            this.$message.warning("该时间段暂无轨迹");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // ------------------------------------------------------------------------
    //  地图API
    initMap() {
      this.center = new TMap.LatLng(28.606169, 115.934794);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      this.measureTool = new TMap.tools.MeasureTool({
        map: this.map,
      });
    },
    // 测距
    handleMeasure() {
      this.measureTool.measureDistance().then((res) => {
        // console.log(res)
      });
    },
    // 设置车辆最新的位置
    setSite() {
      let _self = this;
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 46, // 点标记样式宽度（像素）
            height: 38, // 点标记样式高度（像素）
            src: car, //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(this.site.lat, this.site.lng),
            properties: {
              title: "marker",
            },
          },
        ],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      markerLayer.on("click", function(evt) {
        infoWindow.open(); //打开信息窗
        infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
        //设置信息窗内容
        infoWindow.setContent(`<div>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${
            _self.deviceInfo.PrecisePosition
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
            _self.deviceInfo.Latitude
          }-${_self.deviceInfo.Longitude}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">物联设备：</span>${
            _self.deviceInfo.IMEI ? _self.deviceInfo.IMEI : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">设备所有人：</span>${
            _self.deviceInfo.EquipOwnerName
              ? _self.deviceInfo.EquipOwnerName
              : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">车牌号/司机手机：</span>
          ${
            _self.deviceInfo.VehicleNo ? _self.deviceInfo.VehicleNo : "暂无"
          }/${_self.deviceInfo.DriverPhone ? _self.deviceInfo.DriverPhone : "暂无"}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">最后定位时间：</span>${
            _self.deviceInfo.GPSTime
          }</p>
          </div>`);
      });
    },
    // 切换当前位置
    handleSearchAddress() {
      if (this.searchAddress == "") {
        this.markers.setGeometries([]);
        return;
      }
      this.markers.setGeometries([]);
      (this.markers = new TMap.MultiMarker({
        map: this.map,
        geometries: [],
      })),
        this.geocoder
          .getLocation({ address: this.searchAddress })
          .then((result) => {
            this.map.setCenter(result.result.location);
            this.markers.updateGeometries([
              {
                id: "main",
                position: result.result.location, // 将得到的坐标位置用点标记标注在地图上
              },
            ]);
          })
          .catch((err) => this.$message.error(err.message));
    },
    // 查看车辆轨迹
    setRoute() {
      let _self = this;
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      this.polylineLayer = new TMap.MultiPolyline({
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 8, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "butt", //线端头方式
            showArrow: true,
            arrowOptions: {
              width: 8,
              height: 8,
              space: 20,
            },
          }),
          style_red: new TMap.PolylineStyle({
            color: "#00cc00", //线填充色
            width: 10, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
            showArrow: true,
            arrowOptions: {
              width: 8,
              height: 8,
              space: 20,
            },
          }),
        },
        geometries: [
          {
            styleId: "style_blue",
            paths: this.traceInfo,
          },
        ],
      });
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          "car-down": new TMap.MarkerStyle({
            width: 40,
            height: 40,
            anchor: {
              x: 20,
              y: 20,
            },
            faceTo: "map",
            rotate: 180,
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png",
          }),
          start: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: start,
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: end,
          }),
          marker: new TMap.MarkerStyle({
            width: 18,
            height: 25,
          }),
          newmarker: new TMap.MarkerStyle({
            width: 22,
            height: 30,
            src: address,
          }),
        },
        geometries: [
          {
            id: "car",
            styleId: "car-down",
            position: this.traceInfo[0],
          },
          {
            id: "start",
            styleId: "start",
            position: this.traceInfo[0],
          },
          {
            id: "end",
            styleId: "end",
            position: this.traceInfo[this.traceInfo.length - 1],
          },
          ...this.points,
        ],
      });
      //初始化mark标记点点击事件
      this.infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      this.infoWindow.close(); //初始关闭信息窗关闭
      //监听标注点击事件
      this.marker.on("click", function(evt) {
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng,
        };
        // let params = `${evt.geometry.position.lat},${evt.geometry.position.lng}`
        reverseCoord(params).then((res) => {
          let isAdmin = localStorage.getItem("isAdmin");
          //设置infoWindow
          _self.infoWindow.open(); //打开信息窗
          _self.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
          //设置信息窗内容
          let str = `<div style="text-align:left;">
            <p style="text-align:left;font-size:15px;margin: 0;display:inline-block">
              <span id="text">${evt.geometry.properties.id}</span>
              <textarea id="input" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
              <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(1)">复制</span>
            </p>`;
          if (!!Number(isAdmin)) {
            str += `<span style="color:#409eff;cursor:pointer;margin-left:10px;" onclick="deleteText(${evt.geometry.id}, ${evt.geometry.properties.id})">删除</span>`;
          }
          str += `<p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>
              <span id="text1">${res.data.address}</span>
              <textarea id="input1" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
              <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(2)">复制</span>
            </p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
              evt.geometry.position.lng
            }-${evt.geometry.position.lat}</p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">时间：</span>
              <span id="text2">${evt.geometry.properties.time.split("／")[0]} ${
            evt.geometry.properties.time.split("／")[1]
          }</span>
              <textarea id="input2" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
              <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(3)">复制</span>
            </p>
            <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">停留时间：</span>${
              evt.geometry.properties.TimeDiffer
            }小时</p>
            <p  style="text-align:center;">
              <button class="address_btn" onclick="shipment('${
                res.data.address
              }','${evt.geometry.properties.time}','${
            evt.geometry.position.lng
          }','${evt.geometry.position.lat}')">设置围栏</button>
            </p>
            </div>`;
          _self.infoWindow.setContent(str);
          //<button class="address_btn" onclick="unloading('${res.data.address}','${evt.geometry.properties.time}','${evt.geometry.position.lng}','${evt.geometry.position.lat}')">设置卸货地</button>
        });
      });
      this.circle = new TMap.MultiCircle({
        map: this.map,
        styles: {
          // 设置圆形样式
          circle: new TMap.CircleStyle({
            color: "rgba(74,198,46,0.26)",
            showBorder: true,
            borderColor: "rgba(74,198,46,1)",
            borderWidth: 2,
          }),
        },
        geometries: [],
      });
      // this.startMove()
    },
    copyText(type) {
      let text = "";
      let input = "";
      if (type == 1) {
        text = document.getElementById("text").innerText;
        input = document.getElementById("input");
      } else if (type == 2) {
        text = document.getElementById("text1").innerText;
        input = document.getElementById("input1");
      } else {
        text = document.getElementById("text2").innerText;
        input = document.getElementById("input2");
      }
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message.success("复制成功");
    },
    // 删除marker点
    deleteText(id, rowId) {
      let text = document.getElementById("text").innerText;
      this.$confirm("是否确认删除当前标记点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: text,
            trackKey: this.redisKey,
          };
          DelTrackInfo(params).then((res) => {
            this.marker.remove(id);
            this.infoWindow.close();
          });
        })
        .catch(() => {});
    },
    // 装货地围栏
    shipment(address, time, lng, lat) {
      if (
        this.fenceList.length > 0 &&
        this.fenceList[this.fenceList.length - 1].lat == lat &&
        this.fenceList[this.fenceList.length - 1].lng == lng
      ) {
        this.$message.warning("不同围栏不能是同一个位置");
        return;
      } else {
        this.infoWindow.close();
        this.$prompt("请输入半径(米)", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.radiusValue,
          closeOnClickModal: false,
        })
          .then(({ value }) => {
            this.circle.add({
              styleId: "circle",
              center: new TMap.LatLng(lat, lng),
              radius: Number(value),
              id: "circle" + lat + lng,
            });
            this.fenceList.push({
              address,
              time: String(time)
                .split("／")
                .join(" "),
              lng,
              lat,
              radius: Number(value),
            });
          })
          .catch(() => {});
      }
    },
    // 围栏搜索
    async fenceSearch() {
      if (this.fenceList && this.fenceList.length < 2) {
        this.$message.warning("请选择2个围栏");
        return;
      }
      this.tableData = []; // 搜索清空表格
      let req_CoordInfos = [];
      for (let item of this.fenceList) {
        req_CoordInfos.push({
          PointDate: item.time,
          Coord: item.lng + "|" + item.lat,
          Radius: item.radius,
        });
      }
      let params = {
        trackKey: this.redisKey,
        req_CoordInfos,
      };
      this.loadingFence = true;
      // this.loadingTable = true
      this.GetLoadingPoint(params);
    },
    // GetLoadingPointList 获取起始装货点信息列表
    async GetLoadingPoint(params, index) {
      await GetEquipOverviewLoadingPointList(params).then((res) => {
        this.addressList = res.data;
        this.loadingFence = false;
        // this.locusSearch()
      });
    },
    // 删除围栏
    removeFence(row, index) {
      let newArr = this.fenceList.filter(
        (item) => item.lat != row.lat && item.lng != row.lng
      );
      this.$nextTick(() => {
        this.fenceList = newArr;
        this.circle.remove(this.circle.geometries[index].id);
      });
    },
    //开始移动
    startMove() {
      this.marker.moveAlong(
        {
          car: {
            path: this.traceInfo,
            speed: 5000,
          },
        },
        {
          autoRotation: true,
        }
      );
    },
    //停止移动
    pauseMove() {
      this.marker.pauseMove();
    },
    //重新开始
    resumeMove() {
      this.marker.resumeMove();
    },
    // 删除装货地卸货地
    removeAddress(row) {
      let newArr = this.addressList.filter(
        (item) =>
          item.LoadCoord != row.LoadCoord && item.UnLoadCoord != row.UnLoadCoord
      );
      this.$nextTick(() => {
        this.addressList = newArr;
      });
    },
    // 轨迹匹配
    locusSearch() {
      if (this.addressList.length === 0) {
        this.$message.warning("请选择装货地卸货地");
        return;
      }
      let paramsList = []; // 获取里程参数
      // 获得里程的参数
      for (let item of this.addressList) {
        paramsList.push({
          vehicleNumber: this.bindForm.VehicleCarNumber,
          vehicleColor: this.vehicleColor,
          startDate: item.LoadingDate,
          endDate: item.UnLoadingDate,
          StCoord: item.LoadCoord,
          EdCoord: item.UnLoadCoord,
          redisKey: item.RedisKey,
          TrackKey: this.redisKey,
        });
      }
      document.getElementById(`locusTable`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      // this.$nextTick(()=>{
      //   document.getElementById(`trajectory`).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      // })
      this.getlocus(paramsList);
    },
    // 点击查看轨迹
    detailTrajectory(row) {
      let trajectoryList = this.polylineLayer.getGeometries();
      if (trajectoryList.length > 1) {
        this.polylineLayer.remove(trajectoryList[1].id);
      }
      let params = {
        searchWord: this.bindForm.VehicleCarNumber,
        stDate: row.startDate,
        edDate: row.endDate,
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
        redisKey: this.redisKey,
      };
      this.addPolyArr(params, `pl_${row.Id}`);
    },
    // 添加新的轨迹
    addPolyArr(params, str) {
      this.loading = true;
      getEquipTrack(params)
        .then((res) => {
          if (res.data.list.length > 0) {
            let arr = res.data.list.map((item) => {
              return new TMap.LatLng(item.Lat, item.Lng);
            });
            this.newtraceInfo = arr;
            this.loading = false;
            this.polylineLayer.add({
              //新的折线添加到图层中
              id: str,
              styleId: "style_red",
              paths: this.newtraceInfo,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取分段轨迹列表
    getlocus(params) {
      this.tableData = [];
      this.loadingTable = true;
      GetMileageInfo(params)
        .then((res) => {
          for (let index in this.addressList) {
            this.tableData.push({
              VehicleNumber: res.data[index].VehicleNumber || "",
              Mileage: res.data[index].Mileage,
              loadingPoint: this.addressList[index].LoadAddress,
              unloadingPoint: this.addressList[index].UnLoadAddress,
              startDate: this.addressList[index].LoadingDate,
              endDate: this.addressList[index].UnLoadingDate,
              IsUnusual: res.data[index].IsUnusual,
              Id: res.data[index].Id,
            });
            this.loadingTable = false;
          }
          this.mileageList = JSON.parse(JSON.stringify(this.tableData));
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    // 删除分段轨迹
    removelocus(row) {
      this.polylineLayer.remove(`pl_${row.Id}`);
      let newArr = this.tableData.filter(
        (item) => JSON.stringify(row) != JSON.stringify(item)
      );
      this.$nextTick(() => {
        this.tableData = newArr;
      });
    },
    // 批量删除
    removeAlllocus() {
      let _arr = this.tableData.filter(
        (item) => !this.selectList.includes(item)
      );
      this.$nextTick(() => {
        this.tableData = _arr;
      });
    },
    handleSelectionChange(val) {
      this.selectList = [...val];
    },
    // 导出分段轨迹
    exportExcel() {
      ExportMileageInfo(this.tableData).then((res) => {
        window.open(res.data);
      });
    },
    // 搜索里程类型
    handleMileage() {
      // 大于输入的里程
      if (
        this.mileage != "" &&
        this.mileageList.length > 0 &&
        this.selecttype === 1
      ) {
        this.tableData = [];
        for (let item of this.mileageList) {
          if (Number(item.Mileage) >= Number(this.mileage)) {
            this.tableData.push({
              ...item,
              dander: true,
            });
          } else {
            this.tableData.push({
              ...item,
              dander: false,
            });
          }
        }
        // 小于
      } else if (
        this.mileage != "" &&
        this.mileageList.length > 0 &&
        this.selecttype === 0
      ) {
        this.tableData = [];
        for (let item of this.mileageList) {
          if (Number(item.Mileage) <= Number(this.mileage)) {
            this.tableData.push({
              ...item,
              dander: true,
            });
          } else {
            this.tableData.push({
              ...item,
              dander: false,
            });
          }
        }
      } else {
        this.tableData = [];
        for (let item of this.mileageList) {
          this.tableData.push({
            ...item,
            dander: false,
          });
        }
      }
    },
    handleItem(row) {
      this.map.setCenter(new TMap.LatLng(row.lat, row.lng));
    },
  },
};
</script>
<style lang="scss">
.deviceSkim {
  .circle {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: green;
    border-radius: 50%;
  }
}
.address_btn {
  background-color: #409eff;
  border-color: #409eff;
  padding: 7px 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.warning-row {
  color: red;
}
</style>
<style lang="scss" scoped>
.deviceSkim {
  display: flex;
  flex-direction: column;
  .searchAddress {
    z-index: 1000;
    position: absolute;
    top: 100px;
    left: 347px;
    background: #fff;
    padding: 10px;
    border-radius: 6px;
  }
  .strengthen {
    margin-top: 20px;
    .imgs {
      margin-right: 10px;
    }
  }
  ul li {
    list-style: none;
  }
  .btn-wrap {
    z-index: 999;
    position: absolute;
    bottom: 5.5rem;
    margin-left: 3rem;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  }
  .btn {
    width: 75px;
    height: 30px;
    float: left;
    background-color: #fff;
    color: rgba(27, 142, 236, 1);
    font-size: 14px;
    border: 1px solid rgba(27, 142, 236, 1);
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
  }
  .btn:hover {
    background-color: rgba(27, 142, 236, 0.8);
    color: #fff;
  }
  .map {
    width: 100%;
    height: 1000px;
  }
  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
  }
  .outerBox {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    .top {
      display: flex;
      // justify-content: space-around;
      margin-bottom: 20px;
      .device {
        margin-right: 60px;
      }
    }
  }
  .innerBox {
    display: flex;
    .state {
      width: 20%;
    }
  }
}
.pageTitle {
  display: inline-block;
  margin-right: 16px;
}
.facilityBox {
  .locus_table {
    background-color: #fff;
    overflow: hidden;
    height: 83vh;
    padding: 12px 10px 0px;
    .el-button {
      float: right;
    }
  }
  .content_box {
    display: flex;
    flex: 1;
  }
  .shipment {
    height: 83vh;
    width: 18%;
    min-width: 286px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    padding-left: 16px;
    .shipment_item {
      border: 1px solid #eee;
      width: 100%;
      height: 41.5vh;
      position: relative;
      box-sizing: border-box;
      .address_box {
        height: 36vh;
        overflow-y: auto;
      }
      .addressFence_search {
        position: absolute;
        bottom: 10px;
        width: 90%;
        left: 50%;
        transform: translateX(-51%);
      }
      .addressFence_radio {
        position: absolute;
        bottom: 50px;
        width: 90%;
        left: 50%;
        transform: translateX(-51%);
      }
      .address_item {
        padding: 10px;
        margin-bottom: 8px;
        box-sizing: border-box;
        width: auto;
        display: flex;
        justify-content: start;
        :first-child {
          min-width: 52px;
        }
        .item_btn {
          padding: 2px;
        }
        .item_address {
          display: inline-block;
        }
      }
    }
  }
  .mapBox {
    width: 82%;
    min-width: 750px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
  }
  .locus_btnBox {
    margin-top: 6px;
    width: auto;
    text-align: right;
    // display: flex;
    // flex: 1;
    // justify-content: space-between;
    // line-height: 36px;
    .address_search {
      margin-left: 10px;
    }
  }
}
.collapse_box {
  position: absolute;
  top: 100px;
  left: 40px;
  z-index: 1001;
  width: 300px;
  background-color: #fff;
  border: none;
  .electric_box {
    height: 300px;
    overflow-y: auto;
    padding: 0px 6px;
    .infinite-list-item {
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
    .infinite-list-item:hover {
      background-color: #ddd;
    }
    .active {
      background-color: #ddd;
    }
    .load_item {
      text-align: center;
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .electric_box::-webkit-scrollbar {
    width: 4px;
  }
  .electric_box::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  .electric_box::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  .el-collapse-item {
    box-sizing: border-box;
  }
  ::v-deep .el-collapse-item__header {
    text-align: center;
    display: block;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    .el-collapse-item__arrow {
      float: right;
      margin-top: 16px;
      margin-right: 16px;
    }
  }
}
</style>
