import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 添加设备
export const saveEquipments = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/AddEquip`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 编辑设备
export const editEquipments = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/EditEquip`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        } ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取设备列表
export const equipmentsList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/GetEquipList`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除设备列表
export const delEquipmentsInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/DelEquip?equipIds=${params}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 绑定/解绑设备
export const BindUntieInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/BindUntieInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看设备记录
export const equipmentsInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EquipmentInfo/GetEquipLogList?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询货主基本信息
export const getGoodsOwnerInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetenterPriseInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 批量导入设备
export const deviceExcel = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipmentInfo/ExcelPars`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}
// 保存
export const saveEquipInfoList = (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/EquipmentInfo/SaveEquipInfoList`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}